import transactionsIcon from "../pictures/trade.png";
import inventoryTrackerIcon from "../pictures/inventories.png";
import inventoryIcon from "../pictures/inventory.png";
import sun from "../pictures/sun_2.png";
import moon from "../pictures/half_moon.png";
import draftsIcon from "../pictures/draft.png";
import dashboardIco from "../pictures/dashboard.png";
import logo from "../pictures/am_data.png";
import customersIcon from "../pictures/customers.png";

import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { ServiceComContext } from "./dashboard";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Chip, Divider } from "@mui/material";

const SideNavBar = () => {
  const { collapsed, toggleSidebar } = useProSidebar();
  const { setComIndex } = useContext(ServiceComContext);
  const navigate = useNavigate();

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <Sidebar
        breakPoint="sm"
        transitionDuration={800}
        style={{ height: "100vh", background: "#feffff" }}
      >
        <Menu>
          <div style={{ display: "flex" }}>
            <AppLogo />
          </div>

          <div className="mt-4">{!collapsed && <UserGreatings />}</div>

          <Divider className="my-1" />
          <MenuItem
            onClick={() => {
              navigate("");
              toggleSidebar();
            }}
            icon={<BarIcons img={dashboardIco} />}
          >
            DashBoard
          </MenuItem>
          <Divider className="my-1" />
          <MenuItem
            onClick={() => {
              toggleSidebar();
              navigate("transactions");
            }}
            icon={<BarIcons img={transactionsIcon} />}
          >
            Transactions
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleSidebar();
              navigate("inventory");
            }}
            icon={<BarIcons img={inventoryIcon} />}
          >
            Inventory
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("inventory");
              toggleSidebar();
            }}
            icon={<BarIcons img={inventoryTrackerIcon} />}
          >
            Inventory Tracker
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleSidebar();
              navigate("drafts");
            }}
            icon={<BarIcons img={draftsIcon} />}
          >
            Drafts
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("customers");
              toggleSidebar();
            }}
            icon={<BarIcons img={customersIcon} />}
          >
            Customers
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

const AppLogo = () => {
  const navigate = useNavigate();
  const { collapseSidebar, collapsed } = useProSidebar();

  const toggle = () => {
    collapseSidebar();
  };

  return (
    <div>
      <div
        onClick={() => {
          navigate("/");
        }}
        className=" align-items-center"
        style={{ display: "flex", margin: "10px", justifyContent: "end" }}
      >
        <img
          style={{ cursor: "pointer", width: "50px", height: "50px" }}
          src={logo}
        />

        {!collapsed && <h4 className="m-2">NAKOWA DRINKS</h4>}
      </div>
    </div>
  );
};

const BarIcons = (props) => {
  return (
    <img
      className="circle"
      style={{ width: "60px", height: "60px", padding: "15px" }}
      src={props.img}
    />
  );
};

const UserGreatings = () => {
  const [greating, setGreating] = useState("");
  const [greatingIcon, setGreatingIcon] = useState(sun);
  const { userName } = useContext(ServiceComContext);

  useEffect(() => {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      hour12: true,
    });
    const time = formatter.format(date).split(" ")[1];
    const hour = formatter.format(date).split(" ")[0];
    const hourVal = parseInt(hour);

    let greeting = "";
    if (hourVal >= 5 && hourVal < 12 && time === "AM") {
      greeting = "Good Morning";
    } else if (
      (hourVal >= 1 && hourVal < 5 && time === "PM") ||
      hourVal === 12
    ) {
      greeting = "Good Afternoon";
      setGreatingIcon(moon);
    } else {
      greeting = "Good Evening";
      setGreatingIcon(moon);
    }

    setGreating(greeting);
  });

  return (
    <div className="greating-card p-2">
      <div className="center" style={{ fontSize: "small", color: "white" }}>
        <b>{greating}</b>
        <Avatar
          src={greatingIcon}
          className="m-1"
          sx={{ width: 26, height: 26 }}
        >
          s
        </Avatar>
      </div>
      <div
        className="mx-4"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Chip
          avatar={<Avatar sx={{ bgcolor: "#daab2a" }}>N</Avatar>}
          label={
            <div className="m-2" style={{ color: "white" }}>
              <b>ADMIN</b>
            </div>
          }
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default SideNavBar;
