import React from "react";
import "./App.css";
import { Routes, Route, Router, Navigate, Outlet } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import DashBoard from "./components/dashboard";
import PasswordReset from "./components/passwordReset";
import { ProSidebarProvider } from "react-pro-sidebar";
import MainDashboard from "./components/MainDashboard";
import SettingsMenu from "./components/dashboardMenu";
import Settings from "./components/settings";
import InventoryComponent from "./components/inventory";
import TransactionsComponent from "./components/transactionsComponent";
import TransactionsSummaryComponent from "./components/transactionsSummaryComponent";
import LoginComponent from "./components/loginComponent";
import DraftsComponent from "./components/drafts_component";
import CustomersComponent from "./components/customers_component";

const App = () => (
  <div>
    <ProSidebarProvider>
      <Routes>
        <Route path="/" element={<LoginComponent />} />
        <Route path="login" element={<LoginComponent />} />

        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <DashBoard />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<MainDashboard />} />
          <Route path="transactions" element={<TransactionsComponent />} />
          <Route
            path="transactionsSummary"
            element={<TransactionsSummaryComponent />}
          />
          <Route path="inventory" element={<InventoryComponent />} />
          <Route path="drafts" element={<DraftsComponent />} />
          <Route path="customers" element={<CustomersComponent />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="passreset" element={<PasswordReset />} />
      </Routes>
    </ProSidebarProvider>
  </div>
);

export default App;
