import { Alert, Box, Snackbar, Tab, Tabs } from "@mui/material";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import * as React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ServiceComContext } from "./dashboard";
import Atm from "./atm";
import { useNavigate } from "react-router-dom";
import CarouselComp from "./careusal";
import Marquee from "./marquee";
import TransactionsComponent from "./transactionsSummaryComponent";
import request, { agent } from "superagent";

const MainDashboard = () => {
  const [init, setInit] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalItemsSold, setTotalItemsSold] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalRevenueA, setTotalRevenueA] = useState(0);
  const [totalItemsSoldA, setTotalItemsSoldA] = useState(0);
  const [totalSalesA, setTotalSalesA] = useState(0);
  const [totalRevenueB, setTotalRevenueB] = useState(0);
  const [totalItemsSoldB, setTotalItemsSoldB] = useState(0);
  const [totalSalesB, setTotalSalesB] = useState(0);
  const [lastUpdateA, setLastUpdateA] = useState("");
  const [lastUpdateB, setLastUpdateB] = useState("");

  useEffect(() => {
    if (!init) {
      handleDataFetch();
    }
  }, [init]);

  const handleDataFetch = async () => {
    await request
      .get("https://api.foudhan.com/nakowa/bussinessreport.php")
      .then((response) => {
        const theData = response.body;
        setTotalRevenue(theData.totalRevenue);
        setTotalItemsSold(theData.totalItemsSold);
        setTotalSales(theData.totalSales);

        setTotalRevenueA(theData.totalRevenueA);
        setTotalItemsSoldA(theData.totalItemsSoldA);
        setTotalSalesA(theData.totalSalesA);

        setTotalRevenueB(theData.totalRevenueB);
        setTotalItemsSoldB(theData.totalItemsSoldB);
        setTotalSalesB(theData.totalSalesB);

        setLastUpdateA(theData.lastUpdateA);
        setLastUpdateB(theData.lastUpdateB);

        setInit(true);
      })
      .catch((err) => {
        console.log("Error message:", err.response);
        console.log("ERROR", err);
      });
  };

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol sm={12} lg={6}>
          <WalletCard
            revenue={totalRevenue}
            revenueA={totalRevenueA}
            revenueB={totalRevenueB}
          />
        </MDBCol>
        <MDBCol sm={12} lg={6}>
          <CarouselComp />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md={12}>
          <MDBCard
            style={{
              display: "flex",
            }}
          >
            <WalletFundingCard
              totalQty={totalItemsSold}
              totalQtyA={totalItemsSoldA}
              totalQtyB={totalItemsSoldB}
              totalSales={totalSales}
              totalSalesA={totalSalesA}
              totalSalesB={totalSalesB}
              lastUpdateA={lastUpdateA}
              lastUpdateB={lastUpdateB}
            />
            <div style={{ overflow: "hidden" }}>
              <Marquee text="A very warm welcome note from digital business world @NAKOWA STORE" />
            </div>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md={12}>{<TransactionsComponent />}</MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const WalletCard = (props) => {
  const totalRevenue = props.revenue;
  const totalRevenueA = props.revenueA;
  const totalRevenueB = props.revenueB;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(value);
  };

  const navigate = useNavigate();

  return (
    <div style={{ whiteSpace: "nowrap" }} className="wallet-card">
      <MDBCard
        className=" m-2 p-4 wallet-card-bal-bg"
        style={{ height: "340px" }}
      >
        <div style={{ fontSize: "20px" }}>
          <b>YOUR TRANSACTIONS</b>
        </div>
        <div className="wallet-card-q">
          <MDBContainer>
            <MDBRow className="mb-2 w-100">
              <MDBCol className="text-center">
                <MDBCard className="wallet-label-cards d-flex flex-column align-items-center justify-content-center p-4">
                  <div>
                    Total Revenue
                    <br /> {formatCurrency(totalRevenue)}
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow className="w-100">
              <MDBCol>
                <MDBCard className="wallet-label-cards d-flex flex-column align-items-center justify-content-center p-1">
                  <div>
                    Shop A's Revenue
                    <br /> {formatCurrency(totalRevenueA)}
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol>
                <MDBCard className="wallet-label-cards d-flex flex-column align-items-center justify-content-center p-1">
                  <div>
                    Shop B's Revenue
                    <br /> {formatCurrency(totalRevenueB)}
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow className="w-100">
              <MDBCol>
                <button
                  onClick={() => {
                    navigate("transactionsSummary");
                  }}
                  className="wallet-card-buttons"
                >
                  Transaction history
                </button>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </MDBCard>
    </div>
  );
};

const WalletFundingCard = (props) => {
  const totalItemsSold = props.totalQty;
  const totalSales = props.totalSales;

  const totalItemsSoldA = props.totalQtyA;
  const totalSalesA = props.totalSalesA;
  const lastUpdateA = props.lastUpdateA;

  const totalItemsSoldB = props.totalQtyB;
  const totalSalesB = props.totalSalesB;
  const lastUpdateB = props.lastUpdateB;

  return (
    <div>
      <MDBCard
        className="accounts-cards"
        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      >
        <MDBCardBody>
          <div className="wallet-card-q">
            <MDBRow>
              <MDBCol>
                <MDBCard className="wallet-label-cards d-flex flex-column align-items-center justify-content-center p-2">
                  <div>
                    Total Quantity of Sold Items
                    <br />
                    All: {totalItemsSold} | Shop A: {totalItemsSoldA} | Shop B:
                    {totalItemsSoldB}
                    {30}
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol>
                <MDBCard className="wallet-label-cards d-flex flex-column align-items-center justify-content-center p-2">
                  <div>
                    Total Sales
                    <br /> All: {totalSales} | Shop A: {totalSalesA} | Shop B:{" "}
                    {totalSalesB}
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol>
                <MDBCard className="wallet-label-cards d-flex flex-column align-items-center justify-content-center p-2">
                  <div>
                    Last Update
                    <br /> Shop A: {lastUpdateA} | Shop B: {lastUpdateB}
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default MainDashboard;
