import {
  MDBBtn,
  MDBCard,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import * as React from "react";
import logo from "../pictures/am_data.png";
import { Alert, Collapse, Stack } from "@mui/material";
import TextInput from "./textField";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "./spinner";
import { ReactSession } from "react-client-session";
import ForgotPassword from "./forgotPassword";

export default function LoginComponent() {
  const loginUserName = "nakowa";
  const loginPassword = "123456";
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [progress, setProgress] = useState(false);
  const [userCorrect, setUserCorrect] = useState(false);
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [netConnection, setNetConnection] = useState(false);
  const [reset, setReset] = useState(false);

  ReactSession.setStoreType("localStorage");

  const handleLogin = () => {
    setProgress(true);
    setUserCorrect(false);
    setPasswordCorrect(false);

    // if (navigator.onLine) {
    if (userName === loginUserName) {
      if (password === loginPassword) {
        ReactSession.set("userName", userName);
        navigate("/dashboard");
      } else {
        setProgress(false);
        setPasswordCorrect(true);
      }
    } else {
      setProgress(false);
      setUserCorrect(true);
    }
    // } else {
    //   setNetConnection(true);
    //   setProgress(false);
    // }
  };

  return (
    <div className="login-bg">
      <MDBContainer>
        <MDBCard className="shadow-2 d-flex flex-column align-items-center m-4 p-4">
          <MDBCard
            className="shadow-2"
            style={{ cursor: "pointer", width: "100px" }}
          >
            <MDBCardImage position="top" src={logo}></MDBCardImage>
          </MDBCard>
          <MDBRow className="m-2">
            <MDBCol>
              <div class="text-center m-3">
                <h3>Admin Login</h3>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="m-2">
            <MDBCol>
              <TextInput tValue={setUserName} tLabel="User name" tType="text" />

              <Collapse in={userCorrect}>
                <Alert className="pt-1" severity="error">
                  Incorrect email
                </Alert>
              </Collapse>

              <TextInput
                tValue={setPassword}
                tType="password"
                tLabel="password"
              />

              <Collapse in={passwordCorrect}>
                <Alert className="pt-1" severity="error">
                  Incorrect password
                </Alert>
              </Collapse>
            </MDBCol>
          </MDBRow>

          <MDBRow className="m-2 ">
            <MDBCol>
              <Collapse in={progress}>
                <Stack style={{ color: "grey.500" }} spacing={0.5}>
                  <Spinner />
                </Stack>
              </Collapse>
              <MDBBtn
                className="mt-1 button"
                type="submit"
                onClick={handleLogin}
                disabled={progress}
              >
                SUBMIT
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow className="m-2">
            <MDBCol>
              <Link
                className="d_link"
                to={""}
                onClick={() => {
                  setReset(true);
                }}
              >
                Forgot Password?
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}
