import * as React from "react";
import { useState } from "react";
import { MDBCard } from "mdb-react-ui-kit";
import { MenuItem, ProSidebarProvider } from "react-pro-sidebar";
import "../css/style.css";
import "firebase/compat/storage";
import { firebase } from "../services/config";
import "firebase/compat/database";
import { ReactSession } from "react-client-session";

import SideNavBar from "./sideNavBar";
import { Menu } from "@mui/material";
import { useEffect } from "react";
import TopBar from "./dashboardNavbar";

import { Outlet } from "react-router-dom";

const db = firebase.database();

export const ServiceComContext = React.createContext();

export default function DashBoard() {
  const userEmail = ReactSession.get("email");
  const dashboardRef = React.useRef(null);

  const [walletBalance, setWalletBalance] = useState(0);

  const [comIndex, setComIndex] = useState(0);
  const [userName, setUserName] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [businessName, setBusinessName] = useState("");

  return (
    <ProSidebarProvider>
      <ServiceComContext.Provider
        value={{
          comIndex,
          setComIndex,
          userName,
          walletBalance,
          userEmail,
        }}
      >
        <div ref={dashboardRef} style={{ display: "flex" }}>
          <SideNavBar />
          <div style={{ width: "100%", height: "100vh" }}>
            <div>
              <TopBar />
              <Menu open={openMenu}>
                <MenuItem>Profile</MenuItem>
              </Menu>
            </div>
            <div className="main-content-dsb">
              <div className="d-flex align-items-center justify-content-center">
                <MDBCard className=" shadow-2 m-4 w-100">
                  <ServiceComContext.Consumer>
                    {(contextData) => <Outlet {...contextData} />}
                  </ServiceComContext.Consumer>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </ServiceComContext.Provider>
    </ProSidebarProvider>
  );
}
