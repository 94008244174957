import React from "react";
import { Navigate } from "react-router-dom";
import { ReactSession } from "react-client-session";

const ProtectedRoute = ({ children }) => {
  ReactSession.setStoreType("localStorage");
  const session = ReactSession.get("userName");

  if (session === undefined) {
    return <Navigate to={"/login"} replace />;
  }

  return children;
};

export default ProtectedRoute;
