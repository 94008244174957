import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MDBCard } from "mdbreact";
import slide1 from "../pictures/slides/slide1.jpg";
import slide2 from "../pictures/slides/slide2.jpg";
import slide3 from "../pictures/slides/slide3.jpg";

import { useNavigate } from "react-router-dom";

export default function CarouselComp() {
  const [init, setInit] = useState(false);
  const refPc = useRef(null);
  const navigate = useNavigate();

  const handleDownloadClick = () => {
    const apkUrl = "https://api.foudhan.com/download/foudhandataapp.apk";
    const link = document.createElement("a");
    link.href = apkUrl;
    link.download = "foudhandataapp.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!init) {
      refPc.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setInit(true);
    }
  });
  return (
    <div ref={refPc} className="mt-2 mb-2 rounded">
      <MDBCard className="m-2 p-2 rounded" style={{ flex: 1 }}>
        <Carousel autoPlay infiniteLoop interval={4000} showArrows={true}>
          <div>
            <div className="slide rounded">
              <div className="w-100 h-100" style={{ cursor: "pointer" }}>
                <img className="w-100 h-100" src={slide1} />
              </div>
            </div>
          </div>

          <div className="sec-slide rounded">
            <div className="w-100 h-100" style={{ cursor: "pointer" }}>
              <img className="w-100 h-100" src={slide2} />
            </div>
          </div>

          <div className="slide rounded" style={{ padding: "10px" }}>
            <div className="w-100 h-100 " style={{ cursor: "pointer" }}>
              <img className="w-100 h-100" src={slide3} />
            </div>
          </div>
        </Carousel>
      </MDBCard>
    </div>
  );
}
