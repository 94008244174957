// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyDx7hl9qrUT419cMo3yKbmHicIWvaO8HcY",
  authDomain: "nakowa-a4018.firebaseapp.com",
  projectId: "nakowa-a4018",
  storageBucket: "nakowa-a4018.appspot.com",
  messagingSenderId: "126793972694",
  appId: "1:126793972694:web:694a44f2419e3e01970277",
  measurementId: "G-ZB96QXMSY0",
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database(); // Get the database reference

export { firebase, database };
